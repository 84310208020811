$().ready(function(){
	setupSlideMenu();
	stickyNav();
	StaffScroller();
	//MouthOpen();
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function stickyNav() {	
	$(document).scroll(function(){
		if ($(window).scrollTop() >= 100){
			$("header").addClass("sticky");
		} else if ($(window).scrollTop() < 100){
			$("header").removeClass("sticky");
		}
	});
}

export function checkScrollerSize($wrap){
	$wrap.each(function(){
		var $scroller = $(this).find(".scroller");
		if ($scroller.scrollLeft() == 0){
			$(this).find("#prev").hide();
		} else {
			$(this).find("#prev").show();
		}
		if ($scroller.scrollLeft() + $scroller.innerWidth() >= $scroller[0].scrollWidth) {
			$(this).find("#next").hide();
		} else {
			$(this).find("#next").show();
		}
	});
}
export function StaffScroller(){
	if ($(".staff-container .scroller").length > 0){
		var $wrap = $(".staff-container");
		var $scroller = $wrap.find(".scroller");
		var $distance = $scroller.innerWidth();
		$wrap.each(function(){
			var $next = $wrap.find("#next");
			var $prev = $wrap.find("#prev");

			function slide(offset){
				if (!$scroller.data("animating")){
					$scroller.data("animating", true);
					var newPos;
					if(offset < 0){ //back
						if ($scroller.scrollLeft() > 0){
							newPos = $scroller.scrollLeft() - $distance;
							$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
								checkScrollerSize($wrap);
								$scroller.data("animating", false);
							});
						} else  $prev.hide();
					} else if (offset == 1) { //fwd
						if ($scroller.scrollLeft() + $scroller.innerWidth() < $scroller[0].scrollWidth){
							newPos = $scroller.scrollLeft() + $distance;
							$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
								checkScrollerSize($wrap);
								$scroller.data("animating", false);
							});
						} else $next.hide();
					}
				}
			}
			// click functions
			$prev.on("click touch",function(){
				slide(-1);
			});
			$next.on("click touch",function(){
				slide(1);
			});
		});
		$(window).resize(function(){
			$distance = $scroller.innerWidth();
			var index = Math.round($scroller.scrollLeft()/$distance);
			$scroller.scrollLeft(index*$scroller.innerWidth());
			checkScrollerSize($wrap);}
		);
		checkScrollerSize($wrap);
	}
}

/*
function MouthOpen(){
    var target = Math.round($(".mouth.section").offset().top - 100);
    $(window).scroll(function(){
        if ($(window).scrollTop() >= target){
            $(".mouth.section").addClass("open");
            QuoteScroller();
        }
    });
}
function QuoteScroller(){
    if ($(".mouth .scroller").length > 0){
        var $wrap = $('.quote-container');
        $wrap.each(function(index, element){
            var $scroller = $wrap.find('.scroller');
            var $next = $wrap.find('#next');
            var $prev = $wrap.find('#prev');

            function scrollQuote(offset){
                var $distance = $scroller.innerWidth();
                if (!$scroller.data('animating')){
                    $scroller.data('animating', true);
                    if(offset < 0){ //back
                        if ($scroller.scrollLeft() > 0){
                            var newPos = $scroller.scrollLeft() - $distance;
                            $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                                checkScrollerSize($wrap);
                                $scroller.data('animating', false);
                            });
                        } else  $prev.hide();
                    } else if (offset == 1) { //fwd
                        if ($scroller.scrollLeft() + $scroller.innerWidth() < $scroller[0].scrollWidth){
                            var newPos = $scroller.scrollLeft() + $distance;
                            $scroller.stop().animate({scrollLeft:newPos},500,'swing', function(){
                                checkScrollerSize($wrap);
                                $scroller.data('animating', false);
                            });
                        } else $next.hide();
                    }
                }
            }
             // click functions
            $prev.on('click touch',function(){
                scrollQuote(-1);
            });
            $next.on('click touch',function(){
                scrollQuote(1);
            });
        });
        $(window).resize(function(){checkScrollerSize($wrap);});
        checkScrollerSize($wrap);
    }
}

*/



